body{
    font-family: 'Roboto', sans-serif;
}

.bg-blue{
    background: rgba(#4C8BF5, .4);
}

.padding-section{
    padding: 50px 0 80px 0;

    @media(max-width: 768px){
        padding: 25px 0 40px 0;
    }
}

.table-content, .table-lessons, .table-rent{
    td{
        border: 1px solid gray;
        text-align: center;
        padding: 10px;
    }
}

.section-title{
    color: #000000;		
    font-size: 66px;	
    font-weight: 900;	
    letter-spacing: 2px;	
    line-height: 41px;
    text-align: center;
    margin: 30px 0 50px 0;
    font-family: 'Caveat Brush', cursive;

    @media(max-width: 768px){
        font-size: 36px;
    }
}

.center-mobile{
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}
