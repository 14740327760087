@import 'variables';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css?family=Caveat+Brush&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

// components
@import 'buttons';
@import 'header';
@import 'nav';

@import 'root';

// pages
@import 'page-home';

