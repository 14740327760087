$mobile-breakpoint : 768px;
$hamburger-size: 35px;

.core-nav{
    width: 100%;
    min-height: 51px;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(#4C8BF5, .6);
    z-index: 9999;

    .nav-items{
        padding: 0;
        margin: 0;
        list-style: none;
        

        @media(max-width: $mobile-breakpoint){
            width: 100%;
            display: block !important;
            margin: 0;
            background-color: rgba(0, 0, 0, 0.4);
            position: fixed;
            top: 106px;
            left: 0;
            transform: translateX(-200%);
            transition: .8s transform;
        }
             
        li{
            display: flex;
            margin: 0 15px;
            color: #fff;
            font-weight: 700;
            cursor: pointer;
            text-transform: uppercase;
            letter-spacing: 1px;

            @media(max-width: $mobile-breakpoint){
                justify-content: center;
                margin: 20px 0;
            }
        }
    }
    
    .nav-hamburger{
        display: none;
        cursor: pointer;
        width: $hamburger-size;

        @media(max-width: $mobile-breakpoint){
            display: block;
        }

        span{
            display: block;
            height: 2px;
            position: relative;
            width: 100%;
        
            &:after{
                content: '';
                height: 2px;
                background: #fff;
                display: block;
                position: absolute;
                right: 0;
                transition: all .3s ease; 
            }
        }
            
        span:nth-child(2){
            margin: 9px 0; 
        }

        span:nth-child(1):after{
            width: $hamburger-size - 5px; 
        }

        span:nth-child(2):after{
            width: $hamburger-size; 
        }

        span:nth-child(3):after{
            width: $hamburger-size - 15px;
        }
        
        &:hover{
            span:after{
                width: $hamburger-size; 
            }
        }
              
        &.open{
            span:after{
                width: 35px;
                background: #fff; 
            }

            span:nth-child(1):after {
                transform: translateY(11px) rotateZ(45deg); 
            }

            span:nth-child(2):after {
                width: 0;
                transform: translateX( - (35px / 2) ); 
            }

            span:nth-child(3):after {
                transform: translateY(-11px) rotateZ(-45deg); 
            }
        }
    }
}

@keyframes ghost{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }   
}

body.menu-open{
    .nav-items{
        transform: translateY(0) !important;
        transition: .5s transform;
        animation: ghost 1s forwards;
    }
}
