.button_box{
    text-align: center;
    position: absolute;
    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);
    z-index: 999;

    &__btn, .btn-cologne{
        position: relative;
        color: #fff;		
        font-size: 20px;	
        font-weight: 900;	
        line-height: 27px;
        padding: 13px 36px;
        background-color: #4C8BF5;
        border: none;
        border-radius: 15px;
        outline: none;
        transition: .4s all;
        z-index: 999;

        &:hover{
            box-shadow: 0 5px 30px 0px rgba(#4C8BF5, .5);
        }
    }
}