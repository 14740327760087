#header{
    width: 100%;
    height: 100vh;
    background: url('../src/images/bg.jpg')no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

    &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(#4C8BF5, .4);
        width: 100%;
        height: 100%;
    }

    .header-title{
        padding-top: 200px;
        color: #fff;
        position: relative;
        z-index: 999;
        font-size: 85px;

        @media(max-width: 768px){
            font-size: 60px;
        }
    }

    .header-subtitle{
        color: #fff;
        position: relative;
        z-index: 999;
        font-size: 36px;
    }
    
}