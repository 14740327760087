#about{

    .about_content{
        color: #000000;		
        font-size: 18px;	
        font-weight: 300;	
        // letter-spacing: 0.42px;	
        line-height: 25px;

        strong{
            font-weight: 700;
        }
    }

    .about-photo{
        box-shadow: 3px 3px 19px -3px #FEC403;
    }
}

#cologne{

    .about_content{
        color: #000000;		
        font-size: 18px;	
        font-weight: 300;	
        line-height: 25px;

        strong{
            font-weight: 700;
        }
    }

    .about-photo{
        box-shadow: 3px 3px 19px -3px #FEC403;
    }
}



#footer{
    width: 100%;
    color: #fff;
    font-weight: 500;
    background:#4C8BF5;

    .contact-box{
        a{
            text-decoration: none;
            color: #fff;
        }
    }

    .contact-title{
        font-size: 25px;
    }
}